import { Command } from 'cmdk'
import { useCallback, useContext, useEffect, useState } from 'react'
import CommandWrapper from './CommandWrapper'
import { StateStore } from '../Store'
import { debounce, get } from 'lodash'
import ActionItems from './ActionItems'
import CommandRow, { CommandRowProps } from './CommandRow'
import { Button, Divider, Flex, Text, Checkbox, Box } from '@chakra-ui/react'
import { tw } from '@minupalaniappan/brise'
import Api from '../../lib/api'
import {
  Database,
  Columns,
  Layout,
  HardDrive,
  ChevronRight,
  Edit2,
  Search,
  AlertOctagon,
  List,
} from 'react-feather'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import styles from './CommandMenu.module.css'
import FieldReferences from './FieldReferences'
import PicklistReferences from './PicklistReferences'

export const StyledCommandItem = tw(Command.Item)`
  flex
  items-center
  justify-between
  px-2
  h-15
  hover:bg-purple-200
  hover:rounded-lg
  overflow-hidden
  mx-2.5
`

const StyledCommandText = tw(Text)`
  px-3
  py-2
`

export const IconWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: ${(props: any) => props.backgroundColor};
  border-radius: 5px;
`

const NavButton = ({ title, onClick, style }: any) => {
  return (
    <Button
      size='xs'
      style={{
        fontSize: '10px',
        backgroundColor: '#f4f4f4',
        color: '#707070',
        fontWeight: '600',
        ...style,
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

export const CommandMenu = () => {
  const { state, dispatch } = useContext(StateStore)

  const dispatchedOpen =
    (get(state, 'modal.key', '') as string) == 'commandMenu'

  const [open, setOpen] = useState(dispatchedOpen)

  const baseRef = get(state, 'commandMenu.rootRef.ref')

  const [fields, setFields] = useState<Array<CommandRowProps>>([])
  const [selectedField, setSelectedField] = useState<any | null>(null)
  const [objects, setObjects] = useState<Array<CommandRowProps>>([])
  const [term, setTerm] = useState('')
  const [pages, setPages] = useState<string[]>([])
  const [validationRules, setValidationRules] = useState<Array<any>>([])
  const [isObjectsLoaded, setIsObjectsLoaded] = useState(false)
  const page = pages[pages.length - 1]

  const isFieldReferencesEnabled = true

  const router = useRouter()

  const [fetchedField, setFetchedField] = useState<any>()

  useEffect(() => {
    if (!!selectedField) {
      Api.salesforce.objects.objectName.customFields
        .getCustomField({
          customFieldName: selectedField.field_name,
          objectName: selectedField.object_name,
        })
        .then(field => {
          setFetchedField(field)
        })
    }
  }, [selectedField])

  useEffect(() => {
    if (open) {
      fetchFields(term)
      fetchObjects(term)
    }
  }, [open])

  useEffect(() => {
    if (open) {
      const initializeObjects = async () => {
        try {
          await Api.salesforce.objects.getObjects({ keyword: '' })
          setIsObjectsLoaded(true)
        } catch (error) {
          console.error('Failed to load objects:', error)
        }
      }
      initializeObjects()
    }
  }, [open])

  const fetchObjects = async (keyword: string) => {
    if (keyword === '') {
      setObjects([
        { name: 'Account', label: 'Account' },
        { name: 'Opportunity', label: 'Opportunity' },
        { name: 'Contact', label: 'Contact' },
        { name: 'Lead', label: 'Lead' },
        { name: 'Case', label: 'Case' },
      ] as any[])
    } else {
      if (!!isObjectsLoaded) {
        await Api.salesforce.objects
          .getObjects({ keyword: keyword })
          .then(({ objects }) => {
            setObjects(objects)
          })
      }
    }
  }

  const fetchFields = async (keyword: string) => {
    await Api.salesforce.customFields
      .getCustomFields({ keyword: keyword })
      .then(({ custom_fields }) => {
        let final = [...custom_fields]

        if (
          (keyword.includes('sta') || !!keyword) &&
          process.env.NEXT_PUBLIC_APP_ENV === 'staging'
        ) {
          final = [
            { field_name: 'StageName', object_name: 'Opportunity' },
            ...final,
          ]
        }
        setFields(final)
      })
  }

  const fetchValidationRules = async (keyword: string) => {
    await Api.salesforce.validationRules
      .validationRuleSearch({ keyword: keyword })
      .then(({ validation_rules }) => {
        setValidationRules(validation_rules)
      })
  }

  const [searchLoading, setSearchLoading] = useState(false)

  useEffect(() => {
    if (open) {
      dispatch({ type: 'TOGGLE_SIDEBAR', payload: true })
    }

    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: open ? 'commandMenu' : (get(state, 'modal.key', '') as string),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    setOpen(dispatchedOpen)
  }, [dispatchedOpen])

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && e.metaKey) {
        setOpen(open => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              key: open ? '' : 'commandMenu',
            },
          })

          return !open
        })
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [dispatch, setOpen])

  const debouncedSearch = debounce(
    async term => {
      await fetchFields(term)
      await fetchObjects(term)
      await fetchValidationRules(term)
      setSearchLoading(false)
    },
    500,
    { trailing: true },
  )

  useEffect(() => {
    if (open) {
      setSearchLoading(true)
      const handler = setTimeout(() => {
        debouncedSearch(term)
      }, 500)
      return () => clearTimeout(handler) // Cleanup the timeout
    }
  }, [term, open])

  const toggleModalDispatch = () => {
    if (open) {
      dispatch({
        type: 'TOGGLE_MODAL',
        payload: {
          key: '',
        },
      })
    }

    // Add Clean up State here.

    setOpen(open => !open)
    setPages([])
    setTerm('')
  }

  const maxFields = page === 'Add to Existing Object' ? 0 : 2
  const maxObjects = page === 'Add to Existing Object' ? 5 : 2

  const numObjectsToShow = objects ? Math.min(objects.length, maxObjects) : 0
  const numFieldsToShow = fields && page !== 'Add to Existing Object' ? 15 : 0

  const numValidationRulesToShow = 2

  const searchedNodes = (
    <>
      {objects && numObjectsToShow > 0 ? (
        <Command.Group>
          {objects.slice(0, numObjectsToShow).map((aI: any, key) => (
            <StyledCommandItem key={key} className={styles.hoverBgCustom}>
              <CommandRow
                {...aI}
                description={'Object'}
                onClick={() => {
                  let objectRoute = `/objects/${aI.name}`
                  if (page === 'Add to Existing Object') {
                    objectRoute = `${objectRoute}?tab=import`
                  }
                  router.push(objectRoute)
                  toggleModalDispatch()
                }}
                icon={
                  <IconWrapper backgroundColor={'#EBE4FF'}>
                    <Database
                      width={17}
                      height={17}
                      style={{
                        color: '#6550B9',
                        strokeWidth: '2.5',
                      }}
                    />
                  </IconWrapper>
                }
              />
            </StyledCommandItem>
          ))}
        </Command.Group>
      ) : (
        ''
      )}
      {fields && numFieldsToShow > 0 ? (
        <Command.Group>
          {fields.slice(0, numFieldsToShow).map((aI, key) => {
            const field = {
              label: (aI as any).field_name.replace(/__c$/, ''),
              tags: [(aI as any).object_name],
            }

            return (
              <StyledCommandItem key={key} className={styles.hoverBgCustom}>
                <CommandRow
                  {...(field as any)}
                  onEdit={() => {
                    dispatch({
                      type: 'TOGGLE_MODAL',
                      payload: {
                        key: 'editField',
                        data: aI,
                      },
                    })
                  }}
                  onClick={() => {
                    if (isFieldReferencesEnabled) {
                      setSelectedField(aI)
                      setPages(prev => [...prev, `${field.label} Field`])
                    }
                  }}
                  description={'Field'}
                  icon={
                    <IconWrapper backgroundColor={'#DAF1DB'}>
                      <Columns
                        width={17}
                        height={17}
                        style={{
                          color: '#2A7E3B',
                          strokeWidth: '2.5',
                        }}
                      />
                    </IconWrapper>
                  }
                />
              </StyledCommandItem>
            )
          })}
        </Command.Group>
      ) : (
        ''
      )}

      {validationRules.length > 0 && (
        <Command.Group>
          {validationRules.slice(0, 2).map((validationRule, index) => {
            return (
              <StyledCommandItem key={index} className={styles.hoverBgCustom}>
                <CommandRow
                  label={validationRule.name.replaceAll('_', ' ')}
                  tags={
                    validationRule.object_name
                      ? [validationRule.object_name]
                      : []
                  }
                  onClick={() => {
                    Api.salesforce.validationRules
                      .getValidationRule({
                        id: validationRule.id,
                      })
                      .then(validationRuleMeta => {
                        dispatch({
                          type: 'TOGGLE_MODAL',
                          payload: {
                            key: 'editValidation',
                            data: {
                              name: validationRuleMeta.full_name,
                              object_name: validationRule.object_name,
                              description: validationRuleMeta.description,
                              formula: validationRuleMeta.errorConditionFormula,
                              error_message: validationRuleMeta.errorMessage,
                              error_display_field:
                                validationRuleMeta.errorDisplayField,
                              active: validationRuleMeta.active,
                            },
                          },
                        })
                      })
                  }}
                  description={'Validation Rule'}
                  icon={
                    <IconWrapper backgroundColor={'#F8D7DA'}>
                      <AlertOctagon
                        width={17}
                        height={17}
                        style={{
                          color: '#D9534F',
                          strokeWidth: '2.5',
                        }}
                      />
                    </IconWrapper>
                  }
                />
              </StyledCommandItem>
            )
          })}
        </Command.Group>
      )}
    </>
  )

  const navigationNodes = () => {
    const homeNode = (
      <NavButton
        onClick={() => {
          // Reset pages state when Home is clicked
          setPages([]) // Assuming setPages is the state setter for your pages array
        }}
        style={{
          alignSelf: 'flex-start',
          marginTop: '10px',
          marginLeft: '16px',
        }}
        title='Home'
      />
    )

    if (pages.length === 0) {
      return homeNode
    }

    return (
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        {homeNode}
        <>
          {pages.map((p, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '3px',
                }}
                key={p}
              >
                <ChevronRight
                  height={15}
                  width={15}
                  style={{
                    marginTop: '9px',
                    marginRight: '3px',
                    color: '#707070',
                  }}
                />
                <NavButton
                  onClick={() => {
                    // Remove pages that come after the clicked page
                    const newPages = pages.slice(0, index + 1)
                    setPages(newPages) // Assuming setPages is the state setter for your pages array
                  }}
                  style={{
                    alignSelf: 'flex-start',
                    marginTop: '10px',
                  }}
                  title={p}
                />
              </div>
            )
          })}
        </>
      </div>
    )
  }

  return (
    <CommandWrapper>
      <Command.Dialog
        id='cmd-root'
        {...{
          open: open,
          container: (baseRef ? baseRef?.current : undefined) as any,
          shouldFilter: false,
          onOpenChange: toggleModalDispatch,
          className: '!p-0 flex flex-col',
        }}
        style={{
          width: '600px',
          borderRadius: '10px',
          marginTop: '30px',
          maxHeight: '75vh',
        }}
      >
        {navigationNodes()}
        {(['Add to Existing Object'].includes(page) || page === undefined) && (
          <div
            style={{
              display: 'flex', // Enables flexbox layout
              alignItems: 'center', // Vertically centers the items in the container
            }}
          >
            <Command.Input
              placeholder='Search'
              value={term}
              onValueChange={setTerm}
              className='!p-4'
              autoFocus
              style={{
                flex: 1, // Makes the input flex to take up available space
                fontWeight: '500',
                fontSize: '1.25rem',
              }}
            />
            <NavButton
              style={{
                marginRight: '20px',
              }}
              onClick={() => {
                toggleModalDispatch()
              }}
              title={searchLoading ? 'Loading' : 'ESC'}
            />
          </div>
        )}
        <Divider style={{ marginBottom: '10px' }} />
        <div style={{ overflowY: 'auto' }}>
          {page === 'Add to Existing Object' && (
            <div style={{ paddingBottom: '10px' }}>{searchedNodes}</div>
          )}
          {page === 'Upload a CSV' && (
            <div style={{ paddingBottom: '10px' }}>
              <StyledCommandItem key={0} className={styles.hoverBgCustom}>
                <CommandRow
                  key='startFromNewObject'
                  label={'Start from a New Object'}
                  description={'Add fields in bulk to a new object'}
                  onClick={() => {
                    dispatch({
                      type: 'TOGGLE_MODAL',
                      payload: {
                        key: 'startFromNewObject',
                      },
                    })
                    setPages([])
                  }}
                  icon={
                    <IconWrapper backgroundColor={'black'}>
                      <HardDrive
                        width={17}
                        height={17}
                        style={{
                          color: 'white',
                          strokeWidth: '2.5',
                        }}
                      />
                    </IconWrapper>
                  }
                />
              </StyledCommandItem>
              <StyledCommandItem key={1} className={styles.hoverBgCustom}>
                <CommandRow
                  key='startFromExistingObject'
                  label={'Add to an Existing Object'}
                  description={'Add fields in bulk to an existing object'}
                  onClick={() => {
                    setPages([...pages, 'Add to Existing Object'])
                  }}
                  icon={
                    <IconWrapper
                      backgroundColor={'white'}
                      style={{ border: '1.5px solid black' }}
                    >
                      <HardDrive
                        width={17}
                        height={17}
                        style={{
                          color: 'black',
                          strokeWidth: '2.5',
                        }}
                      />
                    </IconWrapper>
                  }
                />
              </StyledCommandItem>
            </div>
          )}

          <FieldReferences
            page={page}
            selectedField={selectedField}
            onConfirm={() => {
              setPages([])
              setTerm('')
            }}
          />

          {page?.includes('Picklist') && (
            <PicklistReferences
              page={page}
              selectedField={selectedField}
              onConfirm={() => {
                setPages([])
                setTerm('')
              }}
            />
          )}

          {page?.includes('Field') && (
            <div style={{ paddingBottom: '10px' }}>
              {selectedField?.field_name?.includes('__c') && (
                <StyledCommandItem key={0} className={styles.hoverBgCustom}>
                  <CommandRow
                    key='editField'
                    label={'Edit Field'}
                    description={'Edit the custom field'}
                    onClick={() => {
                      dispatch({
                        type: 'TOGGLE_MODAL',
                        payload: {
                          key: 'editField',
                          data: selectedField,
                        },
                      })
                      setPages([])
                    }}
                    icon={
                      <IconWrapper backgroundColor={'black'}>
                        <Edit2 width={18} strokeWidth={2} color={'white'} />
                      </IconWrapper>
                    }
                  />
                </StyledCommandItem>
              )}
              {isFieldReferencesEnabled && (
                <StyledCommandItem key={1} className={styles.hoverBgCustom}>
                  <CommandRow
                    key='fieldReferences'
                    label={'Field References'}
                    description={'Find all the places where this field is used'}
                    onClick={() => {
                      setPages([...pages, 'References'])
                    }}
                    icon={
                      <IconWrapper
                        backgroundColor={'white'}
                        style={{ border: '1.5px solid black' }}
                      >
                        <Search
                          width={17}
                          height={17}
                          style={{
                            color: 'black',
                            strokeWidth: '2.5',
                          }}
                        />
                      </IconWrapper>
                    }
                  />
                </StyledCommandItem>
              )}
              {fetchedField?.picklist_values?.length > 0 && (
                <StyledCommandItem key={1} className={styles.hoverBgCustom}>
                  <CommandRow
                    key='picklistReferences'
                    label={'Picklist References'}
                    description={
                      'Find all the places where the picklist values are used'
                    }
                    onClick={() => {
                      setPages([...pages, 'Picklist'])
                    }}
                    icon={
                      <IconWrapper backgroundColor={'#DAF1DB'}>
                        <List
                          width={17}
                          height={17}
                          style={{
                            color: '#2A7E3B',
                            strokeWidth: '2.5',
                          }}
                        />
                      </IconWrapper>
                    }
                  />
                </StyledCommandItem>
              )}
            </div>
          )}

          {!page && (
            <>
              {term.length > 0 ? searchedNodes : ''}
              <Command.Group
                style={{ paddingBottom: term.length !== 0 ? '10px' : '' }}
              >
                {ActionItems(dispatch, () => {
                  setPages([...pages, 'Upload a CSV'])
                }).map((aI, index) => (
                  <StyledCommandItem
                    key={index}
                    className={styles.hoverBgCustom}
                  >
                    <CommandRow {...aI} />
                  </StyledCommandItem>
                ))}
                {term.length === 0 ? (
                  <div style={{ paddingBottom: '10px' }}>{searchedNodes}</div>
                ) : (
                  ''
                )}
              </Command.Group>
            </>
          )}
        </div>
      </Command.Dialog>
    </CommandWrapper>
  )
}

export default CommandMenu
